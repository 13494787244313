import styles from "./LeftPanel.module.css";
import SULogo from "../../Assets/logos/SULogo.svg";
import Squircle from "../../Assets/logos/Squircle.png";
import UserScanning from "../../Assets/logos/UserScanning.png";
import User from "../../Assets/logos/User.png";
import Add from "../../Assets/logos/Add.png";
import View from "../../Assets/logos/View.png";
import Chart from "../../Assets/logos/Chart.png";

import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const LeftPanel = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("1");
  const generalFields = [
    { text: "Home", icon: Squircle, id: "1", nav: "home" },
    // { text: "Members", icon: UserScanning, id: "2", nav: "members" },
  ];

  const signingsFields = [
    { text: "Add New Signings", icon: Add, id: "3", nav: "addNewSignings" },
    {
      text: "View All Signings",
      icon: View,
      id: "4",
      nav: "viewAllSignings",
    },
    {
      text: "Signing Statistics",
      icon: Chart,
      id: "5",
      nav: "signingStatistics",
    },
  ];

  const announcementFields = [
    {
      text: "Post Announcements",
      icon: Add,
      id: "6",
      nav: "postAnnouncement",
    },
    {
      text: "View Announcements",
      icon: View,
      id: "7",
      nav: "viewAnnouncements",
    },
    { text: "Add Tenders", icon: Add, id: "8", nav: "addTender" },
    { text: "View Tenders", icon: View, id: "9", nav: "viewTenders" },
  ];

  const handleClick = (field) => {
    setActive(field.id);
    navigate(`/${field.nav}`);
  };

  useEffect(() => {
    navigate("/home");
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.heading}>
          <img
            src={SULogo}
            alt='SULogo'
            style={{ width: "4vw", height: "4vw" }}
          />
          <div className={styles.headingText}>
            <h1>Students' Union</h1>
            <h3>BITS Pilani, Pilani Campus</h3>
          </div>
        </div>
        <div className={styles.panelContents}>
          <div>
            <h4>General</h4>
            {generalFields.map((field) => {
              return (
                <div
                  className={
                    field.id === active
                      ? styles.panelTabActive
                      : styles.panelTab
                  }
                  key={field.id}
                  onClick={() => handleClick(field)}
                >
                  <img
                    src={field.icon}
                    alt={field.icon}
                    style={{
                      width: "1.5vw",
                      height: "1.5vw",
                      background:
                        field.id === active
                          ? "linear-gradient(180deg, #5a67fd 0%, #568efc 100%)"
                          : "transparent",
                    }}
                  />
                  <p>{field.text}</p>
                </div>
              );
            })}
          </div>
          <div>
            <h4>Signings</h4>
            {signingsFields.map((field) => {
              return (
                <div
                  className={
                    field.id === active
                      ? styles.panelTabActive
                      : styles.panelTab
                  }
                  key={field.id}
                  onClick={() => handleClick(field)}
                >
                  <img
                    src={field.icon}
                    alt={field.icon}
                    style={{
                      width: "1.5vw",
                      height: "1.5vw",
                      background:
                        field.id === active
                          ? "linear-gradient(180deg, #5a67fd 0%, #568efc 100%)"
                          : "transparent",
                    }}
                  />
                  <p>{field.text}</p>
                </div>
              );
            })}
          </div>
          <div>
            <h4>Announcements</h4>
            {announcementFields.map((field) => {
              return (
                <div
                  className={
                    field.id === active
                      ? styles.panelTabActive
                      : styles.panelTab
                  }
                  key={field.id}
                  onClick={() => handleClick(field)}
                >
                  <img
                    src={field.icon}
                    alt={field.icon}
                    style={{
                      width: "1.5vw",
                      height: "1.5vw",
                      background:
                        field.id === active
                          ? "linear-gradient(180deg, #5a67fd 0%, #568efc 100%)"
                          : "transparent",
                    }}
                  />
                  <p>{field.text}</p>
                </div>
              );
            })}
          </div>
          <div className={styles.footer}>
            <div
              style={{
                width: "3.25vw",
                height: "3.25vw",
                background: "tomato",
                borderRadius: "100%",
              }}
            >
              <img
                src={User}
                alt=''
                style={{
                  width: "3.25vw",
                  height: "3.25vw",
                  borderRadius: "100%",
                }}
              />
            </div>
            <div className={styles.footerText}>
              <h3>Students' Union Tech..</h3>
              <h4>Coordinator</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftPanel;
