import styles from "./ViewAllSignings.module.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { merchList, eventList } from "../../../api/api";
import MerchImg from "../../../Assets/images/MerchImg.png";
import CircularProgress from "@mui/material/CircularProgress";

import { AntSwitch } from "../../../Components/AntSwitch";
import FormGroup from "@mui/material/FormGroup"
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const ViewAllSignings = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [checked, setChecked] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    checked
      ? merchList()
          .then((result) => {
            setData(result.results);
            setLoader(false);
          })
          .catch((err) => console.log(err))
      : eventList()
          .then((result) => {
            setData(result.data);
            setLoader(false);
          })
          .catch((err) => console.log(err));
  }, [checked]);

  const handleViewStats = (id) => {
    checked
      ? navigate(`/viewAllSignings/0${id}`)
      : navigate(`/viewAllSignings/1${id}`);
  };

  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div>
      {{ loader }.loader ? (
        <div className={styles.loader}>
          <center>
            <CircularProgress></CircularProgress>
          </center>
        </div>
      ) : (
        <div className={styles.homeContainer}>
          <div className={styles.title}>
            <h1>Student's Union Technical Team</h1>
            <h2>Home / View All Signings</h2>
          </div>
          <div className={styles.contentBox}>
            <div className={styles.header}>
              <h1>All Signings</h1>
              <div className={styles.toggleSwitch}>
                <FormGroup>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Typography>Events</Typography>
                    <AntSwitch
                      inputProps={{ "aria-label": "ant design" }}
                      checked={checked}
                      onChange={handleToggle}
                    />
                    <Typography>Merch</Typography>
                  </Stack>
                </FormGroup>
              </div>
            </div>
            {!{ checked } ? (
              <div className={styles.cards}>
                {data.map((merch, i) => {
                  return (
                    <div className={styles.card} key={i}>
                      <img src={MerchImg} alt='Merch' />
                      {/* <img src={merch.image} alt='Merch' /> */}
                      <div className={styles.cardDetails}>
                        <div className={styles.cardText}>
                          <h3>{merch.name.substring(0, 20)}</h3>
                          {/* <h4>{merch.data.merch_type}</h4> */}
                          <p>{`Status: ${merch.is_active}`}</p>
                          <p>Start Date: 04 Jan 2023</p>
                          <p>Price: Rs. {merch.price}</p>
                        </div>
                        <div
                          className={styles.cardStats}
                          onClick={() => handleViewStats(merch.static_id)}
                          // onClick={() => console.log(merch.static_id)}
                        >
                          <p>View Stats</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className={styles.cards}>
                {data.map((event, i) => {
                  return (
                    <div className={styles.card} key={i}>
                      <img src={MerchImg} alt='Event' />
                      {/* <img src={event.image} alt='Event' /> */}
                      <div className={styles.cardDetails}>
                        <div className={styles.cardText}>
                          <h3>{event.name.substring(0, 20)}</h3>
                          <p>{`Status: ${event.is_active}`}</p>
                          <p>Start Date: 04 Jan 2023</p>
                          <p>Price: Rs. {event.price}</p>
                        </div>
                        <div
                          className={styles.cardStats}
                          onClick={() => handleViewStats(event.static_id)}
                          // onClick={() => console.log(merch.static_id)}
                        >
                          <p>View Stats</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewAllSignings;
