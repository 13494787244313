import styles from "./Landing.module.css";
import SULogo from "../../Assets/logos/SULogo.svg";
import { useState, useEffect } from "react";
import { tenderDashboardList, tenderDashboardDetails } from "../../api/api";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { coordinatorLogin } from "../../api/api";

import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import GoogleIcon from "@mui/icons-material/Google";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LanguageIcon from "@mui/icons-material/Language";
import PeopleIcon from "@mui/icons-material/People";
import CircularProgress from "@mui/material/CircularProgress";
import GitHubIcon from "@mui/icons-material/GitHub";

import backdrop1 from "../../Assets/images/landing/backdrop1.png";
import backdrop2 from "../../Assets/images/landing/backdrop2.png";
import backdrop3 from "../../Assets/images/landing/backdrop3.png";
import backdrop4 from "../../Assets/images/landing/backdrop4.png";
import backdrop5 from "../../Assets/images/landing/backdrop5.png";
import tenderStockImg from "../../Assets/images/TenderImgLanding.png";

import jh from "../../Assets/images/landing/jh.jpg";
import svm from "../../Assets/images/landing/svm.jpg";
import looters from "../../Assets/images/landing/Looters.png";
import amul from "../../Assets/images/landing/amul.png";
import library from "../../Assets/images/landing/library.jpg";
import pmp from "../../Assets/images/landing/pmp.png";

// import SarthakAggarwal from "../../Assets/images/landing/SarthakAggarwal.png";
// import AbhinavLamba from "../../Assets/images/landing/AbhinavLamba.jpeg";
import AhanBansal from  "../../Assets/images/landing/ahan_bansal.jpeg";
import AryanKhorana from  "../../Assets/images/landing/aryan_khorana.jpeg";
import { useNavigate } from "react-router-dom";

const Landing = ({ setStatus, handleResult, signInWithGoogle }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [tenders, setTenders] = useState([]);
  const [tenderData, setTenderData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [devsDataOG, setDevs] = useState();
  const [devState, setDevState] = useState();

  const navigate = useNavigate();

  const tenderApplyLink = `https://forms.gle/g8Si14ZuBPNhXmax5`;

  const responsiveInitiatives = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const responsiveTenders = {
    desktop: {
      breakpoint: { max: 3000, min: 800 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };


  const text =
    "Lorem ipsum dolor sit amet consectetur. Nascetur fermentum enconsectetur. Nascetur fermentum en consectetur. Nascetur fermentum en im a orci donec turpis platea. A tortor non pretium viverra est. Lorem ipsum dolor sit amet consectetur. Nascetur fermentum enim";

  useEffect(() => {
    tenderDashboardList()
      .then((result) => {
        setTenders(result.data);
        console.log(result.data);
        setLoader(false);
      })
      .catch((err) => console.log(err));

    getdevs().then((response) => {
      setDevs(response.sutt_members);
      setDevState(response.sutt_members.representative);
    });
  }, []);

  const handleView = (id) => {
    tenderDashboardDetails(id)
      .then((result) => {
        // setTenderData(result.data);
        // console.log(result.data);
      })
      .catch((err) => console.log(err));
  };

  const handleDownload = (id) => {
    tenderDashboardDetails(id)
      .then((result) => {
        // setTenderData(result.data);
        // console.log(result.data.form);
      })
      .catch((err) => console.log(err));
  };

  function getdevs() {
    return fetch(
      "https://su-bitspilani.org/su/miscellaneous/get_sutt_member?choice=all",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((responseData) => {
        return responseData;
      })
      .catch((error) => console.warn(error));
  }

  const handleLogin = async () => {
    await coordinatorLogin(username, password).then((result) => {
      if (result.status === 1) {
        setStatus(result.status);
        localStorage.setItem("coord-id", result.data.HTTP_X_COORD_ID);
        localStorage.setItem("status", 1);
      } else {
        handleResult(result);
      }
    });
  };

  const GLogin = styled(Button)({
    fontSize: "1vw",
    lineHeight: "2.25vw",
    margin: "1vw",
  });

  const footerLinks = [
    { title: "Home", link: "home" },
    { title: "Contacts", link: "tenders" },
    { title: "Our Team", link: "team" },
    { title: "Developers", link: "devs" },
    { title: "Privacy Policy", link: "privacy" },
    // { title: "Contact", link: "contact" },
  ];

  const navbarLinks = [
    { title: "Home", link: "home" },
    { title: "Contacts", link: "tenders" },
    { title: "SU Initiatives", link: "suinitiatives" },
    { title: "Meet Our Team", link: "team" },
    { title: "Developers", link: "devs" },
    { title: "Privacy Policy", link: "privacy" },
  ];

  const initiatives = [
    {
      title: "Jawed Habib Salon",
      desc: "Students' Union is proud to announce the Inauguration of a franchise of the internationally renowned Jawed Habib Salon on campus. This is the first ever Jawed Habib franchise inside any Indian Institute.",
      img: `${jh}`,
    },
    {
      title: "Snack Vending Machines",
      desc: "Snack Vending Machines have been installed across 13 locations throughout the campus including LTC and Library. These machines would be serving a wide variety of products.",
      img: `${svm}`,
    },
    {
      title: "Looters",
      desc: "Looters has been introduced as a place to provide the students a variety of items to satisfy their taste buds. It is open till 2 am to satiate the hunger of the GBM.",
      img: `${looters}`,
    },
    {
      title: "Amul Parlour",
      desc: "Provides the residents of campus with a healthy range of food items and drinks to choose from. The parlour is located just outside Gym-G, which is ideal for students, faculties and staff who are returning from an exercise session and need refreshments.",
      img: `${amul}`,
    },
    {
      title: "24x7 Library Services",
      desc: "24x7 library services were offered for the first time during mid semester and compre exams. Extensive surveys were conducted during its implementation as to check how positively the students make use of these services.",
      img: `${library}`,
    },
    {
      title: "Peer Mentorship Program",
      desc: "The program is intended to act as a formal channel of communication between the institute and the freshman students. As part of the program, trained sophomores would guide freshmen regarding any issue.",
      img: `${pmp}`,
    },
  ];

  const suTeam = [
    {
      name: "Ahan Bansal",
      position: "President",
      tel: "+91 96875 13867",
      image_url:
        `${AhanBansal}`,
      linkedin_url: "https://www.linkedin.com/in/ahan-bansal-855452249/",
    },
    {
      name: "Aryan Khorana",
      position: "General Secretary",
      tel: "+91 91493 38624",
      image_url:  `${AryanKhorana}`,
      linkedin_url: "https://www.linkedin.com/in/aryan-khorana-214555250/",
    },
  ];

  return (
    <div className={styles.landingScreen}>
      <div className={styles.backdrop1}>
        <img src={backdrop1} alt="" />
      </div>
      <div className={styles.backdrop2}>
        <img src={backdrop2} alt="" />
      </div>
      <div className={styles.backdrop3}>
        <img src={backdrop3} alt="" />
      </div>
      {/* <div className={styles.backdrop4}>
        <img src={backdrop4} alt="" />
      </div>
      <div className={styles.backdrop5}>
        <img src={backdrop5} alt="" />
      </div> */}
      <div>
        <div id='home' className={styles.homepage}>
          <div className={styles.navbar}>
            <div className={styles.navbarLogo}>
              <img
                src={SULogo}
                alt='SULogo'
              />
              <div className={styles.navbarTitle}>
                <h1>Students' Union</h1>
                <p>BITS Pilani, Pilani Campus</p>
              </div>
            </div>
            <div className={styles.navbarLinks}>
              {navbarLinks.map((e) =>
                e.link === "home" ? (
                  <p
                    className={styles.navbarLinksActive}
                    onClick={() =>
                      document
                        .getElementById(`${e.link}`)
                        .scrollIntoView({ behavior: "smooth" })
                    }
                  >
                    {e.title}
                  </p>
                ) : (
                  <p
                    onClick={() =>
                      e.link === "privacy" ?  (() => {
                        window.scrollTo(0, 0);
                        navigate("/privacy");
                      })() :
                      document
                        .getElementById(`${e.link}`)
                        .scrollIntoView({ behavior: "smooth" })
                    }
                  >
                    {e.title}
                  </p>
                )
              )}
            </div>
          </div>
          <div className={styles.loginSection}>
            <div className={styles.welcomeInfo}>
              <p>Welcome to,</p>
              <h1>Students' Union Web Portal</h1>
              <h2>
                Platform for transparent management of finances in Merchandise
                and Signing.
              </h2>
            </div>
            <div className={styles.loginBox}>
              <h1>Student Login</h1>
              <button onClick={() => signInWithGoogle()}>
                <div className={styles.googleIconWrapper}>
                  <GoogleIcon style={{ margin: "auto 0", fontSize: "inherit" }} />
                </div>
                BITS Mail Login
              </button>
              <h2>OR</h2>
              <h1>Coordinator Login</h1>
              <input
                type='text'
                placeholder='Username'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <br />
              <input
                type='password'
                id='pwd'
                name='pwd'
                placeholder='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className={styles.loginBtn} onClick={handleLogin}>
                <button>Login</button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.landingPageBody}>
          <div className={styles.aboutUs}>
            <div className={styles.contentTitle}>
              <h1>about us</h1>
            </div>
            <div className={styles.content}>
              <p>
                The Students' Union, BITS Pilani is a student body comprising of
                elected members from the GBM and is headed by the President and
                the General Secretary. It is a channel of communication between
                student body and adminstration. Its various roles include
                managing college fests, student bodies, clubs, departments and
                associations on campus.
              </p>
            </div>
          </div>
          <div className={styles.ourMission}>
            <div className={styles.contentTitle}>
              <h1>our mission</h1>
            </div>
            <div className={styles.content}>
              <p>
                To earnestly represent the interests of students of BITS Pilani
                and to improve campus life through the creation and continuation
                of viable and effective student services, to preserve and
                protect the traditions and legends of the University, and to
                support students and student organizations in their academic and
                community endeavors.
              </p>
            </div>
          </div>
          {{ loader }.loader ? (
            <div className={styles.loader}>
              <center>
                <CircularProgress></CircularProgress>
              </center>
            </div>
          ) : (
            <div id='tenders' className={styles.tenders}>
              <h1>Contacts</h1>
              <Carousel
                swipeable={true}
                className={styles.tenderContainer}
                autoPlay={true}
                autoPlaySpeed={3000}
                transitionDuration={3000}
                responsive={responsiveTenders}
                ssr={true}
                showDots={true}
                // infinite={true}
              >
                {/* <div className={styles.tenderContainer}> */}
                {tenders.map((tender) => {
                  return (
                    <div className={styles.tenderCard} key={tender.static_id}>
                      <img
                        src={tenderStockImg}
                        alt=''
                        className={styles.tenderImg}
                      />
                      <div className={styles.tenderContent}>
                        <div className={styles.tenderHeader}>
                          <h1>{tender.heading}</h1>
                          <p>Last date: {tender.last_date}</p>
                          <p>{tender.description.substring(0, 200)}</p>
                        </div>
                        <div className={styles.btns}>
                          <button
                            className={styles.viewBtn}
                            // onClick={() => handleView(tender.static_id)}
                            onClick={() => {
                              handleDownload(tender.static_id);
                              console.log("tender: ", tender);
                            }}
                          >
                            View
                          </button>
                          <button
                            className={styles.applyBtn}
                            // onClick={() => handleDownload(tender.static_id)}
                            onClick={() => window.open(`${tenderApplyLink}`, "_blank")}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* <div className={styles.tenderCard}>
                  <img
                    src={tenderStockImg}
                    alt=''
                    className={styles.tenderImg}
                  />
                  <div className={styles.tenderContent}>
                    <div className={styles.tenderHeader}>
                      <h1>Tender-1</h1>
                      <p>20 Jan 2023</p>
                      <p>{text.substring(0, 200)}...</p>
                    </div>
                    <div className={styles.viewBtn}>
                      <button>View</button>
                      <button>Apply</button>
                    </div>
                  </div>
                </div> */}
              </Carousel>
              {/* </div> */}
            </div>
          )}
          <div id='suinitiatives' className={styles.suInitiatives}>
            <h1>su initiatives</h1>
            <Carousel
              swipeable={true}
              className={styles.suContainer}
              autoPlay={true}
              autoPlaySpeed={3000}
              transitionDuration={3000}
              responsive={responsiveInitiatives}
              ssr={true}
              showDots={true}
              // infinite={true}
            >
              {initiatives.map((e, i) => (
                <div className={styles.suCard}>
                  <div className={styles.suCardTitle}>
                    <h1>0{i + 1}</h1>
                    <h2>{e.title}</h2>
                  </div>
                  <img src={e.img} alt='' className={styles.suCardImg} />
                  <div className={styles.suCardContent}>
                    <p>{e.desc}</p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
          <div id='team' className={styles.team}>
            <h1>meet our team</h1>
            <Carousel
              swipeable={true}
              className={styles.teamContainer}
              autoPlay={true}
              autoPlaySpeed={3000}
              transitionDuration={3000}
              responsive={responsiveInitiatives}
              ssr={true}
              showDots={true}
              // infinite={true}
            >
              {suTeam.map((e, i) => (
                <div className={styles.teamCard}>
                  <img
                    src={e.image_url}
                    alt=''
                    className={styles.teamCardImg}
                  />
                  <div className={styles.teamCardTitle}>
                    <h2>{e.name}</h2>
                    <p>{e.position}</p>
                    <a href={e.linkedin_url}>
                      <LinkedInIcon
                        style={{
                          width: "2.75vw",
                          height: "2.75vw",
                          background: "transparent",
                          color: "white"
                        }}
                      />
                    </a>
                    <p href={e.tel}>Contact: {e.tel}</p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
          <div id='devs' className={styles.devs}>
            <h1>developers</h1>

            <div className={styles.devsContainer}>
              <div className={styles.devsNavbar}>
                <a
                  onClick={() => {
                    setDevState(devsDataOG ? devsDataOG.representative : null);
                  }}
                >
                  <PeopleIcon
                    style={{
                      width: "4vw",
                      height: "6vw",
                      cursor: "pointer",
                      color: devState === (devsDataOG ? devsDataOG.representative : false) ? "#4285F4" : "white"
                    }}
                  />
                </a>
                <a
                  onClick={() => {
                    setDevState(devsDataOG ? devsDataOG.web : null);
                  }}
                >
                  <LanguageIcon
                    style={{
                      width: "4vw",
                      height: "6vw",
                      cursor: "pointer",
                      color: devState === (devsDataOG ? devsDataOG.web : false) ? "#4285F4" : "white"
                    }}
                  />
                </a>
                <a
                  onClick={() => {
                    setDevState(devsDataOG ? devsDataOG.design : null);
                  }}
                >
                  <p style={{ color: devState === (devsDataOG ? devsDataOG.design : false) ? "#4285F4" : "white" }}>
                    UX
                  </p>
                </a>
                <a
                  onClick={() => {
                    setDevState(devsDataOG ? devsDataOG.app : null);
                  }}
                >
                  <p style={{ color: devState === (devsDataOG ? devsDataOG.app : false) ? "#4285F4" : "white" }}>
                    &lt;/&gt;
                  </p>
                </a>
              </div>
              <div className={styles.devsCardsContainer}>
                {devsDataOG ? (
                  devState?.map((e) => (
                    <div className={styles.teamCard}>
                      <img
                        src={e.image_url}
                        alt=''
                        className={styles.teamCardImg}
                      />
                      <div className={styles.teamCardTitle}>
                        <h2>{e.name}</h2>
                        <p>{e.designation}</p>
                        <a href={e.linkedin_url}>
                          <LinkedInIcon
                            style={{
                              width: "2.75vw",
                              height: "2.75vw",
                              background: "transparent",
                              color: "white"
                            }}
                          />
                        </a>
                        <a href={e.work_url} style={{ textDecoration: "none" }}>
                          <GitHubIcon
                            style={{
                              width: "2.75vw",
                              height: "2.75vw",
                              background: "transparent",
                              color: "white"
                            }}
                            href={e.work_url}
                          />
                        </a>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={styles.devsCard}></div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <img src={SULogo} alt='' style={{ width: "8vw", height: "8vw" }} />
            <h1>STUDENTS' UNION, BITS PILANI</h1>
            <p>Pilani, Rajasthan</p>
            <div className={styles.footerLinks}>
              {footerLinks.map((e) => (
                <p
                  onClick={() =>
                    e.link === "privacy" ?  (() => {
                      window.scrollTo(0, 0);
                      navigate("/privacy");
                    })() :
                    document
                      .getElementById(`${e.link}`)
                      .scrollIntoView({ behavior: "smooth" })
                  }
                >
                  {e.title}
                </p>
              ))}
            </div>
            <div className={styles.footerLogoLink}>
              <InstagramIcon
                style={{ width: "2.5vw", height: "2.5vw", cursor: "pointer" }}
                onClick={() => window.open("https://www.instagram.com/su_bitspilani/", "_blank")}
              />
              <FacebookIcon
                style={{ width: "2.5vw", height: "2.5vw", cursor: "pointer" }}
                onClick={() => window.open("https://www.facebook.com/bitspilanistudentunion/", "_blank")}
              />
              <LinkedInIcon
                style={{ width: "2.5vw", height: "2.5vw", cursor: "pointer" }}
                onClick={() => window.open("https://in.linkedin.com/company/bits-pilani-student-union", "_blank")}
              />
            </div>
            <div className={styles.footerLove}>
              <p>Made with love by the Students' Union Technical Team</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
