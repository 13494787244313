import styles from "./EventsAndWorkshop.module.css";
import fillerImageEvents from "../../../../Assets/images/fillerImageEvents.png";
import { createEvent } from "../../../../api/api";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const Merchandise = ({ handleResult }) => {
  const [eventName, setEventName] = useState("");
  const [eventPrice, setEventPrice] = useState("");
  const [eventDesc, setEventDesc] = useState("");
  const [eventVenue, setEventVenue] = useState("");
  const [eventType, setEventType] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [checkCancel, setCheckCancel] = useState(false);
  const [file, setFile] = useState([fillerImageEvents]);
  const [images, setImages] = useState([]);

  var date = new Date();
  var today = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(
    -2
  )}-${("0" + date.getDate()).slice(-2)}`;

  const navigate = useNavigate();

  const handleChange = (e) => {
    setImages(e.target.files);
    file.pop();
    for (let i = 0; i < e.target.files.length; i++) {
      file.push(URL.createObjectURL(e.target.files[i]));
    }
  };

  const handleSubmit = async () => {
    await createEvent(
      eventName,
      eventDesc,
      Number(eventPrice),
      eventVenue,
      eventType,
      eventDate,
      checkCancel,
      images
    )
      .then((result) => {
        console.log(result);

        if (handleResult(result)) {
          setEventName("");
          setEventPrice("");
          setEventDesc("");
          setEventVenue("");
          setEventType("");
          setEventDate("");
          setCheckCancel(false);
          setFile([fillerImageEvents]);
          setImages([]);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={styles.homeContainer}>
      <div className={styles.title}>
        <h1>Student's Union Technical Team</h1>
        <h2>Home / Add New Signings / Merch</h2>
      </div>
      <div className={styles.contentBox}>
        <h1>Events and Workshops</h1>
        <div className={styles.formContainer}>
          <div className={styles.form}>
            <div className={styles.formImage}>
              <label htmlFor="img-upload" className={styles.uploadLabel}>
                Add Image
              </label>
              <input
                type="file"
                className={styles.imgInput}
                id="img-upload"
                multiple
                name="Event Image"
                accept="image/*"
                alt="text"
                onChange={handleChange}
              />
              <Carousel showThumbs={false} showIndicators={false}>
                {file.map((item) => {
                  return (
                    <div className={styles.imgCarouselDiv}>
                      <img src={item} alt="Error" />
                    </div>
                  );
                })}
              </Carousel>
            </div>
            <div className={styles.formDetails}>
              <input
                type="text"
                name="EventName"
                id="EventName"
                placeholder="Merchandise Name"
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
              />
              <input
                type="date"
                name="MerchDate"
                id="MerchDate"
                placeholder="Date"
                value={eventDate}
                min={today}
                onChange={(e) => setEventDate(e.target.value)}
              />
              <input
                type="text"
                name="MerchVenue"
                id="MerchVenue"
                placeholder="Venue"
                value={eventVenue}
                onChange={(e) => setEventVenue(e.target.value)}
              />
              <input
                type="text"
                name="EventType"
                id="EventType"
                placeholder="Event Type"
                value={eventType}
                onChange={(e) => setEventType(e.target.value)}
              />
              <input
                type="number"
                step={50}
                min={0}
                id="MerchPrice"
                placeholder="Price"
                value={eventPrice}
                onChange={(e) => setEventPrice(e.target.value)}
              />
              <textarea
                name="MerchDesc"
                id="MerchDesc"
                cols="30"
                rows="5"
                placeholder="Description"
                value={eventDesc}
                onChange={(e) => setEventDesc(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className={styles.formOptions}>
            <div className={styles.formOptionsCancellable}>
              <p>Cancellable</p>
              <input
                type="checkbox"
                name="Cancellable"
                id="Cancellable"
                checked={checkCancel}
                onChange={() => setCheckCancel(!checkCancel)}
              />
            </div>
          </div>
        </div>
        <div className={styles.buttons}>
          <button
            className={styles.back}
            onClick={() => navigate("/addNewSignings")}
          >
            Back
          </button>
          <button className={styles.submit} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Merchandise;
