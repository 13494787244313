import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom"; // Change to BrowserRouter before deploying the final project.
// Also, change "homepage" in package.json to remove the '/#' at the end of the url for BrowserRouter

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
