import styles from "./ViewTenders.module.css";
import { viewTender, delTender } from "../../../api/api";
import { useEffect, useState } from "react";
import TenderImg from "../../../Assets/images/TenderImg.png";
import TenderDelete from "../../../Assets/images/TenderDelete.png";
import CircularProgress from "@mui/material/CircularProgress";

const ViewTenders = ({ handleResult }) => {
  const [tenders, setTenders] = useState([]);
  const [data, setdata] = useState(0);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    viewTender()
      .then((result) => {
        setTenders(result.data);
        console.log(result.data);
        setLoader(false);
      })
      .catch((err) => console.log(err));
    setdata(0);
  }, []);

  const handleDelete = (id) => {
    delTender(id)
      .then((result) => {
        handleResult(result);
        // setSuccess(true);
        // setSnackbarMsg(result.message);
        // setOpenSnackbar(true);
        viewTender()
          .then((result) => {
            setTenders(result.data);
          })
          .catch((err) => console.log(err));
        setdata(0);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      {{ loader }.loader ? (
        <div className={styles.loader}>
          <center>
            <CircularProgress></CircularProgress>
          </center>
        </div>
      ) : (
        <div className={styles.homeContainer}>
          <div className={styles.title}>
            <h1>Student's Union Technical Team</h1>
            <h2>Home / View Tenders</h2>
          </div>
          <div className={styles.contentBox}>
            <h1>View Tenders</h1>
            <div className={styles.cards}>
              {tenders.map((tender) => {
                return (
                  <div key={tender.static_id} className={styles.card}>
                    <img
                      src={TenderImg}
                      alt='Default Tender'
                      style={{ float: "left", margin: "0 2vw 0 0" }}
                    />
                    <div className={styles.cardHeading}>
                      <h3>{tender.heading}</h3>
                      <p>20 January 2023</p>
                    </div>
                    <p>{tender.description}</p>
                    <div className={styles.cardFooter}>
                      <p>Read More..</p>
                      <img
                        src={TenderDelete}
                        onClick={() => handleDelete(tender.static_id)}
                        alt=''
                        style={{
                          background: "transparent",
                          float: "right",
                          width: "2.7vw",
                          height: "2.7vw",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewTenders;
