import styles from "./Members.module.css";
import { getMembers, delMember } from "../../../api/api";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { withStyles } from "@mui/styles";
import TenderDelete from "../../../Assets/images/TenderDelete.png";

const Members = () => {
  const [loader, setLoader] = useState(true);
  const [membersList, setMembersList] = useState([]);
  const [totalMembers, setTotalMembers] = useState(0);

  useEffect(() => {
    getMembers().then((result) => {
      console.log(result.data);
      setMembersList(result.data);
      setTotalMembers(result.data.length);
      setLoader(false);
    });
  }, []);

  const handleDelete = (id) => {
    console.log("Delete Member");
    delMember(id)
      .then((result) => {
        console.log("HEHE");
      })
      .catch((err) => console.log(err));
  };

  const handleDownload = () => {
    console.log("Download");
  };

  const handleAddMember = () => {
    console.log("Add Member");
  };

  const handleRemoveAll = () => {
    console.log("Remove All");
  };

  function createData(name, id, static_id) {
    return { name, id, static_id };
  }

  const rows = [];

  for (var i = 0; i < totalMembers; i++) {
    rows[i] = createData(
      membersList[i].student.profile.name,
      membersList[i].student.profile.bits_id,
      membersList[i].student.profile.static_id
    );
  }

  const StyledTableCell = withStyles({
    root: {
      color: "white",
      textAlign: "left",
    },
  })(TableCell);

  return (
    <div>
      {{ loader }.loader ? (
        <div className={styles.loader}>
          <center>
            <CircularProgress></CircularProgress>
          </center>
        </div>
      ) : (
        <div>
          <div className={styles.homeContainer}>
            <div className={styles.title}>
              <h1>Student's Union Technical Team</h1>
              <h2>Members</h2>
            </div>
            <div className={styles.contentBox}>
              <div className={styles.header}>
                <h1>Total Members: {totalMembers}</h1>
                <div className={styles.buttons}>
                  <div className={styles.standardButton}>
                    <button onClick={() => handleAddMember()}>
                      Add Member
                    </button>
                    <button onClick={() => handleDownload()}>
                      Download Excel
                    </button>
                  </div>
                  <div className={styles.removeButton}>
                    <button onClick={() => handleRemoveAll()}>
                      Remove All
                    </button>
                  </div>
                </div>
              </div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>ID</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        style={{ color: "white" }}
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.id}
                        </StyledTableCell>
                        <StyledTableCell>
                          <img
                            src={TenderDelete}
                            onClick={() => handleDelete(row.static_id)}
                            alt=""
                            style={{
                              background: "transparent",
                              width: "2vw",
                              height: "auto",
                              cursor: "pointer",
                            }}
                          />
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Members;
