// Testing URL
// const url = "https://onetap.bits-sutechteam.org/back"; // NO TRAILING SLASH

// Deployment URL, uncomment for deployment
const url = "https://su-bitspilani.org/su"; // NO TRAILING SLASH

const auth_headers = {
  "x-authorization":
    "048f1579b8b8f75f609f036ecb26623ddd0f58d4ff9193a14d4284ac4ff0c87b9093ed08947f25ea72cd141b23be5f2b12e10ccf4522c327f8172f76d1554fb6",
  "x-origin": "826bead8ad2ad9ce955028045788f371",
};

const get_api = async (link) => {
  let res = null;

  await fetch(`${url}/${link}`, {
    method: "GET",
    headers: {
      "x-coord-id": localStorage.getItem("coord-id"),
      ...auth_headers,
    },
  })
    .then((response) => {
      if (Math.floor(response.status / 100) === 5) {
        let override = {
          message: `Some error occured`,
        };
        return override;
      }
      return response.json();
    })
    .then((result) => (res = result))
    .catch((error) => (res = error));

  return res;
};

const post_api = async (link, data) => {
  let res = null;

  await fetch(`${url}/${link}`, {
    method: "POST",
    headers: {
      "x-coord-id": localStorage.getItem("coord-id"),
      ...auth_headers,
    },
    body: data,
  })
    .then((response) => {
      if (Math.floor(response.status / 100) === 5) {
        let override = {
          message: `Some error occured`,
        };
        return override;
      }
      return response.json();
    })
    .then((result) => (res = result))
    .catch((error) => (res = error));

  return res;
};

// Kept separate as this one does not send authorization headers
export const coordinatorLogin = async (username, password) => {
  let res = null;

  await fetch(`${url}/users/coordinator-login/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  })
    .then((response) => response.json())
    .then((result) => (res = result))
    .catch((error) => (res = error));
  return res;
};

export const createTender = async (heading, description, pdf, last_date) => {
  let data = new FormData();
  data.append("heading", heading);
  data.append("description", description);
  data.append("form", pdf);
  data.append("last_date", last_date);

  return await post_api("tenders/create/", data);
};

export const viewTender = async () => {
  return await get_api("tenders/list/");
};

export const tenderDashboardList = async () => {
  return await get_api("tenders/dashboard/list");
};

export const tenderDashboardDetails = async (id) => {
  return await get_api(`tenders/dashboard/details/${id}`).then((result) => window.open(`${url}/${result.data.form}`));
};

// Has logic in it as it is the only DELETE method API right now.
// If another one is ever added, create a delete_api() function
export const delTender = async (id) => {
  let res = null;
  await fetch(`${url}/tenders/delete/${id}`, {
    method: "DELETE",
    headers: {
      "x-coord-id": localStorage.getItem("coord-id"),
      ...auth_headers,
    },
  })
    .then((response) => response.json())
    .then((result) => (res = result))
    .catch((error) => (res = error));

  return res;
};

export const delMember = async (id) => {
  let res = null;
  await fetch(`${url}/users/assoc-members/${id}`, {
    method: "DELETE",
    headers: {
      "x-coord-id": localStorage.getItem("coord-id"),
      ...auth_headers,
    },
  })
    .then((response) => response.json())
    .then((result) => (res = result))
    .catch((error) => (res = error));

  return res;
};

export const postAnnouncement = async (
  title,
  description,
  notif,
  image,
  attachment
) => {
  let data = new FormData();
  data.append("title", title);
  data.append("description", description);
  data.append("notification_message", notif);
  data.append("image", image);
  data.append("attachment", attachment);

  return await post_api("signings-api/announcements/add/", data);
};

export const viewAnnouncements = async () => {
  return await get_api("signings-api/announcements/all/");
};

export const addSigning = async (name, price, items, desc, images, cancel) => {
  let data = new FormData();
  data.append("name", name);
  data.append("price", price);
  items.forEach((item) => data.append("items", JSON.stringify(item)));
  data.append("description", desc);
  Array.from(images).forEach((img) => data.append("images", img));
  data.append("non_cancellable", !cancel);

  return await post_api("signings-api/wear/", data);
};

export const eventList = async () => {
  return await get_api("signings-api/event/list/");
};

export const merchList = async () => {
  return await get_api("signings-api/wear/list/");
};

export const wearDetails = async (id) => {
  return await get_api(`signings-api/wear/${id}`);
};

export const eventDetails = async (id) => {
  return await get_api(`signings-api/event/${id}`);
};

export const createEvent = async (
  name,
  desc,
  price,
  venue,
  type,
  date,
  cancellable,
  images
) => {
  let data = new FormData();
  data.append("name", name);
  data.append("price", price);
  data.append("event_venue", venue);
  data.append("event_type", type);
  data.append("event_date", date);
  data.append("description", desc);
  Array.from(images).forEach((img) => {
    data.append("images", img);
  });
  data.append("non_cancellable", cancellable);

  return await post_api("signings-api/event/", data);
};

export const signingStats = async (id) => {
  return await get_api(
    `signings-api/wear/statistics/${id}`
  );
};

export const getMembers = async () => {
  return await get_api("users/assoc-members/");
};

export const deactivateMerchSigning = async (id) => {
  let res = null;
  await fetch(`${url}/signings-api/wear/close/${id}`, {
    method: "PATCH",
    headers: {
      "x-coord-id": localStorage.getItem("coord-id"),
      ...auth_headers,
    },
  })
    .then((response) => (res = response.json()))
    .then((result) => (res = result))
    .catch((error) => (res = error));

  return res;
};

export const deactivateEventSigning = async (id) => {
  let res = null;
  await fetch(`${url}/signings-api/event/close/${id}`, {
    method: "PATCH",
    headers: {
      "x-coord-id": localStorage.getItem("coord-id"),
      ...auth_headers,
    },
  })
    .then((response) => (res = response.json()))
    .then((result) => (res = result))
    .catch((error) => (res = error));

  return res;
};

export const genMerchExcel = async (id) => {
  let data = new FormData();
  data.append("static_id", id);

  return await post_api("signings-api/gen-wear-excel/", data).then(result => window.open(`${url}/${result.message}`));
};

export const uploadMerchExcel = async (id, file) => {
  let data = new FormData();
  data.append("static_id", id);
  data.append("file", file);

  return await post_api("signings-api/upload-wear-excel/", data);
};

export const genEventExcel = async (id) => {
  let data = new FormData();
  data.append("static_id", id);

  return await post_api("signings-api/gen-event-excel/", data).then(result => window.open(`${url}/${result.message}`));
};

export const uploadEventExcel = async (id, file) => {
  let data = new FormData();
  data.append("static_id", id);
  data.append("file", file);

  return await post_api("signings-api/upload-event-excel/", data);
};



