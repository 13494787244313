import styles from "./NewAnnouncement.module.css";
import { useState } from "react";
import { postAnnouncement } from "../../../api/api";

const NewAnnouncement = ({ handleResult }) => {
  const [formTitle, setTitle] = useState("");
  const [formNotifs, setNotifs] = useState("");
  const [formDescription, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [imgName, setImgName] = useState("No Image Selected");
  const [attachment, setAttachment] = useState(null);
  const [fileName, setFileName] = useState("No PDF Selected");

  const handlePost = async () => {
    await postAnnouncement(
      formTitle,
      formDescription,
      formNotifs,
      image,
      attachment
    )
      .then((result) => {
        console.log(result);

        if (handleResult(result)) {
          setTitle("");
          setNotifs("");
          setDescription("");
          setImage(null);
          setAttachment(null);
          setImgName("No Image Selected");
          setFileName("No PDF Selected");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={styles.homeContainer}>
      <div className={styles.title}>
        <h1>Student's Union Technical Team</h1>
        <h2>Home / Post Announcement </h2>
      </div>
      <div className={styles.contentBox}>
        <h1>Announcement</h1>
        <div className={styles.formContent}>
          <input
            id={styles.formHeading}
            type="text"
            name="AnnouncementTitle"
            placeholder="Type Announcement Heading here....."
            value={formTitle}
            onChange={(e) => setTitle(e.target.value)}
          />
          <textarea
            id={styles.formDescription}
            type="text"
            name="AnnouncementDescription"
            placeholder="Type Content here....."
            value={formDescription}
            onChange={(e) => setDescription(e.target.value)}
          />
          <input
            id={styles.formHeading}
            type="text"
            name="AnnouncementNotif"
            placeholder="Type Custom Announcement Notification Description here....."
            value={formNotifs}
            onChange={(e) => setNotifs(e.target.value)}
          />
          <div className={styles.buttons}>
            <label className={styles.addImage} htmlFor="img-upload">
              Add Image
            </label>
            <input
              className={styles.imageInput}
              type="file"
              id="img-upload"
              name="Add Image"
              accept="image/*"
              alt="text"
              onChange={(e) => {
                setImage(e.target.files[0]);
                setImgName(e.target.files[0].name);
              }}
            />
            <label className={styles.addPDF} htmlFor="file-upload">
              Add PDF
            </label>
            <input
              className={styles.pdfInput}
              id="file-upload"
              type="file"
              name="Add PDF"
              accept="application/pdf"
              alt="text"
              onChange={(e) => {
                setAttachment(e.target.files[0]);
                setFileName(e.target.files[0].name);
              }}
            />
            <button className={styles.post} onClick={handlePost}>
              Post
            </button>
          </div>
          <div className={styles.confirm}>
            <div>Image: {imgName}</div>
            <div>Attachment: {fileName}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAnnouncement;
