import fillerImageMerch from "../../../../../Assets/images/fillerImageMerch.png";
import { addSigning } from "../../../../../api/api";
import { forwardRef, useImperativeHandle, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import styles from "../Merchandise.module.css";

const SingleMerch = forwardRef(({ handleResult }, ref) => {
    const [merchName, setMerchName] = useState("");
    const [merchPrice, setMerchPrice] = useState("");
    const [merchDesc, setMerchDesc] = useState("");
    const [checkCustom, setCheckCustom] = useState(false);
    const [checkCancel, setCheckCancel] = useState(false);
    const [file, setFile] = useState([fillerImageMerch]);

    const [images, setImages] = useState([]);

    const options = ["T-shirt", "Hoodie", "T-shirt+Hoodie", "Other"];
    const [merchType, setMerchType] = useState(options[0]);

    const handleChange = (e) => {
        setImages(e.target.files);
        file.pop();
        for (let i = 0; i < e.target.files.length; i++) {
            file.push(URL.createObjectURL(e.target.files[i]));
        }
    };

    useImperativeHandle(ref, () => ({
        async handleSubmit() {
            let item = [{
                item_name: merchName,
                merch_type: merchType,
                has_custom_name: checkCustom
            }];
            await addSigning(merchName, Number(merchPrice), item, merchDesc, images, checkCancel)
                .then((result) => {
                    console.log(result);
    
                    if (handleResult(result)) {
                        setMerchName("");
                        setMerchPrice("");
                        setMerchDesc("");
                        setMerchType(options[0]);
                        setCheckCustom(false);
                        setCheckCancel(false);
                        setFile([fillerImageMerch]);
                        setImages([]);
                    }
                })
                .catch((err) => console.log(err));
        }
    }))
    

    return (
        <div className={styles.formContainer}>
            <div className={styles.form}>
                <div className={styles.formImage}>
                    <div className={styles.inputWrapper}>
                        <label htmlFor='img-upload' className={styles.uploadLabel}>
                            Add Images
                        </label>
                        <input
                            type='file'
                            className={styles.imgInput}
                            id='img-upload'
                            multiple
                            name='Signing Image'
                            accept='image/*'
                            alt='text'
                            onChange={handleChange}
                        />
                    </div>
                    <Carousel showThumbs={false} showIndicators={false}>
                        {file.map((item) => {
                            return (
                                <div className={styles.imgCarouselDiv}>
                                    <img src={item} alt='Error' />
                                </div>
                            );
                        })}
                    </Carousel>
                </div>
                <div className={styles.formDetails}>
                    <input
                        type='text'
                        name='MerchName'
                        id='MerchName'
                        placeholder='Merchandise Name'
                        value={merchName}
                        onChange={(e) => setMerchName(e.target.value)}
                    />
                    <select
                        name='MerchandiseType'
                        id='MerchType'
                        placeholder='Merchandise Type'
                        value={merchType}
                        onChange={(e) => setMerchType(e.target.value)}
                    >
                        {options.map((value) => (
                            <option value={value} key={value}>
                                {value}
                            </option>
                        ))}
                    </select>
                    <input
                        type='number'
                        step={50}
                        min={0}
                        name='MerchPrice'
                        id='MerchPrice'
                        placeholder='Price'
                        value={merchPrice}
                        onChange={(e) => setMerchPrice(e.target.value)}
                    />
                    <textarea
                        name='MerchDesc'
                        id='MerchDesc'
                        cols='30'
                        rows='5'
                        placeholder='Description'
                        value={merchDesc}
                        onChange={(e) => setMerchDesc(e.target.value)}
                    ></textarea>
                </div>
            </div>
            <div className={styles.formOptions}>
                <div className={styles.formOptionsCustomName}>
                    <p>Custom Name</p>
                    <input
                        type='checkbox'
                        name='CustomName'
                        id='CustomName'
                        checked={checkCustom}
                        onChange={() => setCheckCustom(!checkCustom)}
                    />
                </div>
                <div className={styles.formOptionsCancellable}>
                    <p>Cancellable</p>
                    <input
                        type='checkbox'
                        name='Cancellable'
                        id='Cancellable'
                        checked={checkCancel}
                        onChange={() => setCheckCancel(!checkCancel)}
                    />
                </div>
            </div>
        </div>
    );
});

export default SingleMerch;
