import styles from "./Home.module.css";
import Diwali from "../../../Assets/images/Diwali.png";
import { eventList } from "../../../api/api";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";

const Home = () => {
  const [events, setEvents] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    eventList()
      .then((result) => {
        setEvents(result.data);
        setLoader(false);
        console.log(result);
      })
      .catch((err) => console.log(err));
  }, []);

  console.log(events);

  return (
    <div>
      {{ loader }.loader ? (
        <div className={styles.loader}>
          <center>
            <CircularProgress></CircularProgress>
          </center>
        </div>
      ) : (
        <div className={styles.homeContainer}>
          <div className={styles.title}>
            <h1>Student's Union Technical Team</h1>
            <h2>Home</h2>
            <div className={styles.contentBox}>
              {events.map((event) => (
                <div key={event.static_id} className={styles.contentCard}>
                  {event.images.length !== 0 && (
                    <img src={event.images[0].image} alt="" />
                  )}
                  <div className={styles.contentCardTitle}>
                    <h3>{event.name}</h3>
                    {/* <p>20 January 2023</p> */}
                    <p>{`${new Date(event.event_date).getDate()} ${new Date(
                      event.event_date
                    ).toLocaleString("default", { month: "long" })} ${
                      new Date(event.event_date).getYear() + 1900
                    }`}</p>
                  </div>
                  <p></p>
                  <p>{event.description}</p>
                </div>
              ))}
              {/* <div className={styles.contentCard}>
                <img src={Diwali} alt="" />
                <div className={styles.contentCardTitle}>
                  <h3>Deepawali Night</h3>
                  <p>20 January 2023</p>
                </div>
                <p>
                  Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  eu turpis molestie, dictum est a, mattis tellus. Sed
                  dignissim, metus nec fringilla accumsan, risus sem
                  sollicitudin lacus, ut interdum tellus elit sed risus.
                  Maecenas eget condimentum velit, sit amet feugiat lectus.
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos. Praesent auctor purus luctus
                  enim egestas.
                </p>
                <p>
                  Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  eu turpis molestie, dictum est a, mattis tellus. Sed
                  dignissim, metus nec fringilla accumsan, risus sem
                  sollicitudin lacus, ut interdum tellus elit sed risus.
                  Maecenas eget condimentum velit, sit amet feugiat lectus.
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos. Praesent auctor purus luctus
                  enim egestas.
                </p>
                <p>
                  Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  eu turpis molestie, dictum est a, mattis tellus. Sed
                  dignissim, metus nec fringilla accumsan, risus sem
                  sollicitudin lacus, ut interdum tellus elit sed risus.
                  Maecenas eget condimentum velit, sit amet feugiat lectus.
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos. Praesent auctor purus luctus
                  enim egestas.
                </p>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
