import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LeftUserPanel from "../../Components/UserPanels/LeftUserPanel";
import RightUserPanel from "../../Components/UserPanels/RightUserPanel";

import styles from "./GoogleSignedIn.module.css";

const GoogleSignedIn = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // let uid = sessionStorage.getItem("UID");

        // if (!uid) navigate("/");
    }, []);

    return (
        <div className={styles.main}>
            <LeftUserPanel />
            <div className={styles.center}></div>
            <RightUserPanel />
        </div>
    );
}

export default GoogleSignedIn;
