import styles from "./NewTender.module.css";
import { useState } from "react";
import { createTender } from "../../../api/api";

const NewTender = ({ handleResult }) => {
  const [tenderHead, setTenderHead] = useState("");
  const [tenderContent, setTenderContent] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [pdf, setPDF] = useState(null);
  const [pdfName, setPDFName] = useState("No PDF Selected");

  console.log(lastDate);

  const handlePost = async () => {
    await createTender(tenderHead, tenderContent, pdf, lastDate)
      .then((result) => {
        console.log(result);

        if (handleResult(result)) {
          setTenderHead("");
          setTenderContent("");
          setLastDate("");
          setPDF(null);
          setPDFName("No PDF Selected");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={styles.homeContainer}>
      <div className={styles.title}>
        <h1>Student's Union Technical Team</h1>
        <h2>Home / Add Tender</h2>
      </div>
      <div className={styles.contentBox}>
        <h1>New Tenders</h1>
        <div className={styles.formContent}>
          <input
            id={styles.tenderHeading}
            type="text"
            name="tenderHeading"
            placeholder="Type Tender Heading here....."
            value={tenderHead}
            onChange={(e) => setTenderHead(e.target.value)}
          />
          <textarea
            id={styles.tenderContent}
            type="text"
            name="tenderContent"
            placeholder="Type Content here....."
            value={tenderContent}
            onChange={(e) => setTenderContent(e.target.value)}
          />
          <div className={styles.dateWrapper}>
            <input
              type="date"
              name="MerchDate"
              id="MerchDate"
              placeholder="Date"
              value={lastDate}
              onChange={(e) => setLastDate(e.target.value)}
            />
            <p>Last Date of Tender</p>
          </div>
          <div className={styles.buttons}>
            <label className={styles.addPDF} htmlFor="file-upload">
              Add PDF
            </label>
            <input
              className={styles.pdfInput}
              id="file-upload"
              type="file"
              name="Add PDF"
              accept="application/pdf"
              alt="text"
              onChange={(e) => {
                setPDF(e.target.files[0]);
                setPDFName(e.target.files[0].name);
              }}
            />
            <button className={styles.post} onClick={handlePost}>
              Post
            </button>
          </div>
          <div className={styles.confirm}>Attachment: {pdfName}</div>
        </div>
      </div>
    </div>
  );
};

export default NewTender;
