import styles from "./ViewAnnouncements.module.css";
import Diwali from "../../../Assets/images/Diwali.png";
import { viewAnnouncements } from "../../../api/api";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";

const ViewAnnouncements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [data, setData] = useState();
  const [loader, setLoader] = useState(true);

  // const auth_headers = {
  //   "x-authorization":
  //     "048f1579b8b8f75f609f036ecb26623ddd0f58d4ff9193a14d4284ac4ff0c87b9093ed08947f25ea72cd141b23be5f2b12e10ccf4522c327f8172f76d1554fb6",
  //   "x-origin": "826bead8ad2ad9ce955028045788f371",
  // };

  // const get_api = async (link) => {
  //   let res = null;

  //   await fetch(link, {
  //     method: "GET",
  //     headers: {
  //       "x-coord-id": localStorage.getItem("coord-id"),
  //       ...auth_headers,
  //     },
  //   })
  //     .then((response) => {
  //       if (Math.floor(response.status / 100) === 5) {
  //         let override = {
  //           message: `Server error: status code ${response.status}`,
  //         };
  //         return override;
  //       }
  //       return response.json();
  //     })
  //     .then((result) => (res = result))
  //     .catch((error) => (res = error));

  //   return res;
  // };

  useEffect(() => {
    viewAnnouncements()
      .then((result) => {
        setData(result);
        setAnnouncements(result.results);
        setLoader(false);
        // result.data.next !== null
        //   ? () => {
        //       announcements.push(get_api(result.data.next));
        //     }
        //   : console.log("displayed all");
      })
      .catch((err) => console.log(err));
  }, []);

  console.log(data);
  console.log(announcements);

  return (
    <div>
      {{ loader }.loader ? (
        <div className={styles.loader}>
          <center>
            <CircularProgress></CircularProgress>
          </center>
        </div>
      ) : (
        <div className={styles.homeContainer}>
          <div className={styles.title}>
            <h1>Student's Union Technical Team</h1>
            <h2>View Announcements</h2>
            <div className={styles.contentBox}>
              {announcements.map((e) => {
                return (
                  <div className={styles.contentCard}>
                    <img
                      src={Diwali}
                      alt=''
                      style={{ width: "13%", height: "13%" }}
                    />
                    <div className={styles.contentCardTitle}>
                      <h3>{e.title}</h3>
                      <p>{e.created_at.substring(0, 10)}</p>
                    </div>
                    <p>{e.description}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewAnnouncements;
