import styles from "./AddNewSignings.module.css";
import Events from "../../../Assets/images/Events.svg";
import RightArrow from "../../../Assets/logos/rightArrow.png";
import Merchandise from "../../../Assets/images/Merchandise.svg";
import { useNavigate } from "react-router-dom";

const AddNewSignings = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.homeContainer}>
      <div className={styles.title}>
        <h1>Student's Union Technical Team</h1>
        <h2>Home / Add New Signings</h2>
      </div>
      <div className={styles.contentBox}>
        <h1>New Signings</h1>
        <div className={styles.cards}>
          <div
            className={styles.card}
            onClick={() => navigate("/addNewSignings/Events")}
          >
            <div className={styles.cardImage}>
              <img
                src={Events}
                alt="Events"
                style={{
                  width: "16vw",
                  height: "10vw",
                  background: "transparent",
                }}
              />
            </div>
            <div className={styles.cardText}>
              <h3>Events and Workshops</h3>
              <img
                src={RightArrow}
                alt="RightArrow"
                style={{
                  width: "2.5vw",
                  height: "2.5vw",
                  background: "transparent",
                }}
              />
            </div>
          </div>
          <div
            className={styles.card}
            onClick={() => navigate("/addNewSignings/Merch")}
          >
            <div className={styles.cardImage}>
              <img
                src={Merchandise}
                alt="Merchandise"
                style={{
                  width: "16vw",
                  height: "10vw",
                  background: "transparent",
                }}
              />
            </div>
            <div className={styles.cardText}>
              <h3>Merchandise</h3>
              <img
                src={RightArrow}
                alt="RightArrow"
                style={{
                  width: "2.5vw",
                  height: "2.5vw",
                  background: "transparent",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewSignings;
