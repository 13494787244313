import "./App.css";
import React from "react";

import { useEffect, useState } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { Snackbar, Alert, AlertTitle } from "@mui/material";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import firebaseConfig from "./config/firebaseConfig";

import Landing from "./Pages/Landing/Landing";
import Home from "./Pages/General/Home/Home";
import Members from "./Pages/General/Members/Members";
import LeftPanel from "./Components/LeftPanel/LeftPanel";
import RightPanel from "./Components/RightPanel/RightPanel";
import AddNewSignings from "./Pages/Signings/AddNewSigning/AddNewSignings";
import ViewAllSignings from "./Pages/Signings/ViewAllSignings/ViewAllSignings";
import SigningStats from "./Pages/Signings/SigningStats/SigningStats";
import Merchandise from "./Pages/Signings/AddNewSigning/Merchandise/Merchandise";
import EventsAndWorkshop from "./Pages/Signings/AddNewSigning/EventsAndWorkshop/EventsAndWorkshop";
import NewTender from "./Pages/Tenders/NewTender/NewTender";
import ViewTenders from "./Pages/Tenders/ViewTenders/ViewTenders";
import NewAnnouncement from "./Pages/Announcements/PostAnnouncements/NewAnnouncement";
import ViewAnnouncements from "./Pages/Announcements/ViewAnnouncements/ViewAnnouncements";
import SigningsInfo from "./Pages/Signings/SigningsInfo/SigningsInfo";
import GoogleSignedIn from "./Pages/GoogleSignedIn/GoogleSignedIn";
import Privacy from "./Pages/Privacy/Privacy";

function App() {
  const [status, setStatus] = useState();

  const navigate = useNavigate();

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  const provider = new GoogleAuthProvider();

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;

        sessionStorage.setItem("UID", result.user.uid);
        sessionStorage.setItem("displayName", result.user.displayName);
        sessionStorage.setItem("email", result.user.email);

        navigate("/user");

        console.log("credential: ", credential);
        console.log("token: ", token);
        console.log("user: ", user);
      }).catch((error) => {
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log("credential: ", credential);
        console.log("error: ", error);
      });
  }

  useEffect(() => {
    // localStorage.setItem("status", 0);
    const status = JSON.parse(localStorage.getItem("status"));
    if ({ status }) {
      setStatus(status);
    }
  }, []);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [snackbarMsg, setSnackbarMsg] = React.useState("");

  const handleResult = (result) => {
    setSuccess(Boolean(result.status));
    setSnackbarMsg(result.message);
    setOpenSnackbar(true);

    return Boolean(result.status);
  };

  // function removeLoader() {
  //   document.querySelector(".loader-back").style.opacity = "0";
  //   setTimeout(function () {
  //     document.querySelector(".section").style.display = "";
  //     document.querySelector(".loader-back").style.display = "none";

  //     // sal();
  //   }, 450);
  // }

  // setTimeout(function () {
  //   if (document.readyState === "complete") {
  //     console.log("working");
  //     removeLoader();
  //   } else {
  //     window.onload = function () {
  //       removeLoader();
  //     };
  //   }
  // }, 4000);

  // useEffect(() => {
  //   localStorage.setItem("status", status);
  // }, [status]);

  return (
    <React.Fragment>
      <div className='App'>
        {/* <div class='loader-back'>
          <div class='loader-logo'>
            <object>
              <embed src='./images/logo2.svg' />
            </object>
          </div>
        </div> */}
        {{ status }.status ? (
          <div>
            <div className='mainContainer'>
              <LeftPanel />
              <div className='centerPanel'>
                <Routes>
                  <Route path='/home' element={<Home />}></Route>
                  <Route path='/members' element={<Members />}></Route>
                  <Route
                    path='/addNewSignings'
                    element={<AddNewSignings />}
                  ></Route>
                  <Route
                    path='/addNewSignings/Merch'
                    element={<Merchandise handleResult={handleResult} />}
                  ></Route>
                  <Route
                    path='/addNewSignings/Events'
                    element={<EventsAndWorkshop handleResult={handleResult} />}
                  ></Route>
                  <Route
                    path='/viewAllSignings'
                    element={<ViewAllSignings />}
                  ></Route>
                  <Route
                    path='/viewAllSignings/:id'
                    element={<SigningsInfo />}
                  ></Route>
                  <Route
                    path='/signingStatistics'
                    element={<SigningStats />}
                  ></Route>
                  <Route
                    path='/postAnnouncement'
                    element={<NewAnnouncement handleResult={handleResult} />}
                  ></Route>
                  <Route
                    path='/viewAnnouncements'
                    element={<ViewAnnouncements />}
                  ></Route>
                  <Route
                    path='/addTender'
                    element={<NewTender handleResult={handleResult} />}
                  ></Route>
                  <Route
                    path='/viewTenders'
                    element={<ViewTenders handleResult={handleResult} />}
                  ></Route>
                  <Route
                    path='/'
                    element={<Navigate to='/home' replace />}
                  ></Route>
                  <Route
                    path='/*'
                    element={<Navigate to='/home' replace />}
                  ></Route>
                </Routes>
              </div>
              <RightPanel setStatus={setStatus} />
            </div>
          </div>
        ) : (
          <div>
            <Routes>
              <Route
                path='/'
                element={<Landing setStatus={setStatus} handleResult={handleResult} signInWithGoogle={signInWithGoogle} />}
              />
              <Route
                path='/user'
                element={<GoogleSignedIn />}
              />
              <Route
                path='/privacy'
                element={<Privacy />}
              />
              <Route
                path='/*'
                element={<Navigate to='/' replace />}
              />
            </Routes>
          </div>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          sx={{ backgroundColor: "#00000000" }}
          open={openSnackbar}
          autoHideDuration={10000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            sx={{
              width: "25vw",
              backgroundColor: "#111416",
              border: "2px solid white",
              borderRadius: "8px",
            }}
            severity={success ? "success" : "error"}
            onClose={() => setOpenSnackbar(false)}
          >
            <AlertTitle
              sx={{ color: success ? "green" : "red", fontWeight: "bold" }}
            >
              {success ? "Success" : "Error"}
            </AlertTitle>
            <strong>{snackbarMsg}</strong>
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  );
}

export default App;
