import fillerImageMerch from "../../../../../Assets/images/fillerImageMerch.png";
import { addSigning } from "../../../../../api/api";
import { forwardRef, useImperativeHandle, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import styles from "../Merchandise.module.css";

import edit_ico from "../../../../../Assets/logos/Edit.svg";
import delete_ico from "../../../../../Assets/logos/Delete.svg";
import close_ico from "../../../../../Assets/logos/Close.svg";

import { Add } from "@mui/icons-material";
import { Modal } from "@mui/material";

const Combo = forwardRef(({ handleResult }, ref) => {
    const [merchName, setMerchName] = useState("");
    const [merchPrice, setMerchPrice] = useState("");
    const [merchDesc, setMerchDesc] = useState("");
    const [checkCustom, setCheckCustom] = useState(false);
    const [checkCancel, setCheckCancel] = useState(false);
    const [file, setFile] = useState([fillerImageMerch]);

    const [images, setImages] = useState([]);

    const [combos, setCombos] = useState([]);

    const options = ["T-shirt", "Hoodie", "T-shirt+Hoodie", "Other"];

    const [openModal, setOpenModal] = useState(false);

    const [modalName, setModalName] = useState("");
    const [modalType, setModalType] = useState(options[0]);

    const modalAdd = () => {
        setCombos([...combos,
            {
                item_name: modalName,
                merch_type: modalType
            }
        ]);
        setModalName("");
        setModalType(options[0]);
        setOpenModal(false);
    }

    const handleChange = (e) => {
        setImages(e.target.files);
        file.pop();
        for (let i = 0; i < e.target.files.length; i++) {
            file.push(URL.createObjectURL(e.target.files[i]));
        }
    };

    useImperativeHandle(ref, () => ({
        async handleSubmit() {
            combos.forEach(item => item.has_custom_name = checkCustom);

            await addSigning(merchName, Number(merchPrice), combos, merchDesc, images, checkCancel)
                .then((result) => {
                    console.log(result);

                    if (handleResult(result)) {
                        setMerchName("");
                        setMerchPrice("");
                        setMerchDesc("");
                        setCombos([]);
                        setCheckCustom(false);
                        setCheckCancel(false);
                        setFile([fillerImageMerch]);
                        setImages([]);
                    }
                })
                .catch((err) => console.log(err));
        }
    }))

    return (
        <div className={styles.formContainer}>
            <Modal open={openModal} onClose={() => setOpenModal(false)} sx={{ backgroundColor: '#00000000' }}>
                <div className={styles.modal}>
                    <div className={styles.modalTitle}>
                        Enter Details
                        <img src={close_ico} draggable={false} onClick={() => setOpenModal(false)} />
                    </div>
                    <div className={styles.modalDetails}>
                        <input
                            type='text'
                            name='MerchName'
                            id='MerchName'
                            placeholder='Merchandise Name'
                            value={modalName}
                            onChange={(e) => setModalName(e.target.value)}
                        />
                        <select
                            name='MerchandiseType'
                            id='MerchType'
                            placeholder='Merchandise Type'
                            value={modalType}
                            onChange={(e) => setModalType(e.target.value)}
                        >
                            {options.map((value) => (
                                <option value={value} key={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button className={styles.modalButton} onClick={modalAdd}>Add</button>
                </div>
            </Modal>
            <div className={styles.form}>
                <div className={styles.formImage}>
                    <div className={styles.inputWrapper}>
                        <label htmlFor='img-upload' className={styles.uploadLabel}>
                            Add Images
                        </label>
                        <input
                            type='file'
                            className={styles.imgInput}
                            id='img-upload'
                            multiple
                            name='Signing Image'
                            accept='image/*'
                            alt='text'
                            onChange={handleChange}
                        />
                    </div>
                    <Carousel showThumbs={false} showIndicators={false}>
                        {file.map((item) => {
                            return (
                                <div className={styles.imgCarouselDiv}>
                                    <img src={item} alt='Error' />
                                </div>
                            );
                        })}
                    </Carousel>
                </div>
                <div className={styles.comboDetails}>
                    <div className={styles.merchCombo}>
                        <div className={styles.merchComboHeading}>Add Merch For Combo</div>
                        {combos.map((item, i) => {
                            return (
                                <div className={styles.merchItem} key={i}>
                                    {item.item_name}
                                    <div className={styles.merchItemIcons}>
                                        <img src={edit_ico} draggable={false} />
                                        <img src={delete_ico} draggable={false} onClick={() => {
                                            setCombos(combos.filter((data, index) => i !== index));
                                        }} />
                                    </div>
                                </div>
                            )
                        })}
                        <button className={styles.merchComboButton} onClick={() => setOpenModal(true)}>
                            Add New Merch <Add sx={{ fontSize: '1vw', backgroundColor: '#00000000' }} />
                        </button>
                    </div>
                    <div className={styles.formOptions}>
                        <div className={styles.formOptionsCustomName}>
                            <p>Custom Name</p>
                            <input
                                type='checkbox'
                                name='CustomName'
                                id='CustomName'
                                checked={checkCustom}
                                onChange={() => setCheckCustom(!checkCustom)}
                            />
                        </div>
                        <div className={styles.formOptionsCancellable}>
                            <p>Cancellable</p>
                            <input
                                type='checkbox'
                                name='Cancellable'
                                id='Cancellable'
                                checked={checkCancel}
                                onChange={() => setCheckCancel(!checkCancel)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.form}>
                <div className={styles.formDetails}>
                    <input
                        type='text'
                        name='MerchName'
                        id='MerchName'
                        placeholder='Merchandise Name'
                        value={merchName}
                        onChange={(e) => setMerchName(e.target.value)}
                    />
                    <input
                        type='number'
                        step={50}
                        min={0}
                        name='MerchPrice'
                        id='MerchPrice'
                        placeholder='Price'
                        value={merchPrice}
                        onChange={(e) => setMerchPrice(e.target.value)}
                    />
                </div>
                <div className={styles.formDetails}>
                    <textarea
                        name='MerchDesc'
                        id='MerchDesc'
                        cols='30'
                        rows='5'
                        placeholder='Description'
                        value={merchDesc}
                        onChange={(e) => setMerchDesc(e.target.value)}
                    />
                </div>
            </div>
        </div>
    );
});

export default Combo;
