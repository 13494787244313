import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Combo from "./components/Combo";
import SingleMerch from "./components/SingleMerch";

import styles from "./Merchandise.module.css";

const Merchandise = ({ handleResult }) => {
  const [page, setPage] = useState(0);

  const ref0 = useRef();
  const ref1 = useRef();

  const navigate = useNavigate();

  return (
    <div className={styles.homeContainer}>
      <div className={styles.title}>
        <h1>Student's Union Technical Team</h1>
        <h2>Home / Add New Signings / Merch</h2>
      </div>
      <div className={styles.contentBox}>
        <h1>Merchandise</h1>
        <div className={styles.radio}>
          <button className={page ? styles.radioButton : styles.radioButtonSelected} onClick={() => setPage(0)}>Single Merch</button>
          <button className={page ? styles.radioButtonSelected : styles.radioButton} onClick={() => setPage(1)}>Combo</button>
        </div>
        {!page ? <SingleMerch handleResult={handleResult} ref={ref0} /> : <Combo handleResult={handleResult} ref={ref1} />}
        <div className={styles.buttons}>
          <button
            className={styles.back}
            onClick={() => navigate("/addNewSignings")}
          >
            Back
          </button>
          <button className={styles.submit} onClick={() => !page ? ref0.current.handleSubmit() : ref1.current.handleSubmit()}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Merchandise;
