import styles from "./RightPanel.module.css";
import TitleBackdrop from "../../Assets/images/RightPanelBlur.png";
import { useNavigate } from "react-router-dom";

const RightPanel = ({ setStatus }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    setStatus(0);
    localStorage.removeItem("coord-id");
    localStorage.setItem("status", 0);
    navigate("/home");
  };
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h1>Coordinater</h1>
          <h1>Web Portal</h1>
        </div>
        <img
          src={TitleBackdrop}
          alt=""
          style={{
            position: "relative",
            top: "-9vw",
            background: "transparent",
          }}
        />
        <div className={styles.footer} onClick={handleLogout}>
          <p>Log Out</p>
        </div>
      </div>
    </div>
  );
};

export default RightPanel;
