import styles from "./SigningsInfo.module.css";
import { useParams } from "react-router-dom";
import { useState, useEffect, forwardRef } from "react";
import {
  wearDetails,
  genMerchExcel,
  uploadMerchExcel,
  deactivateMerchSigning,
  eventDetails,
  genEventExcel,
  uploadEventExcel,
  deactivateEventSigning,
} from "../../../api/api";

import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { withStyles } from "@mui/styles";

// import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
// import Slide from "@mui/material/Slide";

const SigningsInfo = () => {
  const [signingName, setSigningName] = useState("");
  const [studentsList, setStudentsList] = useState([]);
  const [totalSignings, setTotalSignings] = useState(0);
  const [excel, setExcel] = useState(null);

  const [loader, setLoader] = useState(true);
  const params = useParams();

  useEffect(() => {
    console.log(params.id.substring(0, 1));
    params.id.substring(0, 1) === "0"
      ? wearDetails(params.id.substring(1)).then((result) => {
          console.log(result.data);
          setSigningName(result.data.name);
          setTotalSignings(result.data.total_signings);
          setStudentsList(result.data.students);
          setLoader(false);
        })
      : eventDetails(params.id.substring(1)).then((result) => {
          console.log(result.data);
          setSigningName(result.data.name);
          setTotalSignings(result.data.total_signings);
          setStudentsList(result.data.students);
          setLoader(false);
        });
  }, [params.id]);

  function createData(name, id, size) {
    return { name, id, size };
  }

  const rows = [];

  for (var i = 0; i < studentsList.length; i++) {
    rows[i] = createData(
      studentsList[i].student.profile.name,
      studentsList[i].student.profile.bits_id,
      studentsList[i].size
    );
  }

  const StyledTableCell = withStyles({
    root: {
      color: "white",
      textAlign: "left",
    },
  })(TableCell);

  const handleDownload = () => {
    console.log("Download");
    params.id.substring(0, 1)
      ? genMerchExcel(params.id.substring(1))
          .then((res) => {
            console.log(res.message);
          })
          .catch((error) => {
            console.log(error);
          })
      : genEventExcel(params.id.substring(1))
          .then((res) => {
            console.log(res.message);
          })
          .catch((error) => {
            console.log(error);
          });
  };

  const handleUpload = () => {
    console.log("Upload");
    params.id.substring(0, 1) ? console.log("hehe") : console.log("NOIP");
    if (excel !== null) {
      params.id.substring(0, 1)
        ? uploadMerchExcel(params.id.substring(1), excel).catch((error) => {
            console.log(error);
          })
        : uploadEventExcel(params.id.substring(1), excel).catch((error) => {
            console.log(error);
          });
    }
  };

  const handleDeactivate = () => {
    console.log("Deactivate");
    params.id.substring(0, 1)
      ? deactivateMerchSigning(params.id.substring(1)).catch((error) => {
          console.log(error);
        })
      : deactivateEventSigning(params.id.substring(1)).catch((error) => {
          console.log(error);
        });
    // setOpen(false);
  };

  // const Transition = forwardRef(function Transition(props, ref) {
  //   return <Slide direction='up' ref={ref} {...props} />;
  // });

  // const [open, setOpen] = useState(false);
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <div>
      {{ loader }.loader ? (
        <div className={styles.loader}>
          <center>
            <CircularProgress></CircularProgress>
          </center>
        </div>
      ) : (
        <div className={styles.homeContainer}>
          <div className={styles.title}>
            <h1>Student's Union Technical Team</h1>
            <h2>Home / View All Signings</h2>
          </div>
          <div className={styles.contentBox}>
            <div className={styles.header}>
              <div>
                <h1>{signingName.substring(0, 35)}</h1>
                <div className={styles.subHeader}>
                  <h2>Net Signings: {totalSignings}</h2>
                  <div>
                    <button onClick={() => handleDeactivate()}>
                      Deactivate
                    </button>
                    {/* <Dialog
                      open={open}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={() => handleClose()}
                      aria-describedby='alert-dialog-slide-description'
                    >
                      <DialogTitle>
                        {"Use Google's location service?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id='alert-dialog-slide-description'>
                          Let Google help apps determine location. This means
                          sending anonymous location data to Google, even when
                          no apps are running.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => handleClose()}>Disagree</Button>
                        <Button onClick={() => handleDeactivate()}>
                          Agree
                        </Button>
                      </DialogActions>
                    </Dialog> */}
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.excelActions}>
                  <button onClick={() => handleDownload()}>
                    Download Excel
                  </button>
                </div>
                <div>
                  <label
                    htmlFor='file-upload'
                    className={styles.addExcel}
                    onClick={() => handleUpload()}
                  >
                    Upload Excel
                  </label>
                  <input
                    className={styles.excelInput}
                    id='file-upload'
                    type='file'
                    name='Upload Excel'
                    accept='application/xlsx'
                    alt='text'
                    onChange={(e) => {
                      setExcel(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>ID</StyledTableCell>
                    <StyledTableCell>Size</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      style={{ color: "white" }}
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <StyledTableCell component='th' scope='row'>
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align='right'>{row.id}</StyledTableCell>
                      <StyledTableCell align='right'>
                        {row.size}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default SigningsInfo;
