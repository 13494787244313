import SULogo from "../../Assets/logos/SULogo.svg";
import User from "../../Assets/logos/User.png";

import styles from "./LeftUserPanel.module.css";

const LeftUserPanel = () => {
  const displayName = sessionStorage.getItem("displayName");
  const email = sessionStorage.getItem("email");
  const BITSID = String(email).slice(0, 9).toLocaleUpperCase();
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.heading}>
          <img
            src={SULogo}
            alt='SULogo'
            style={{ width: "4vw", height: "4vw" }}
          />
          <div className={styles.headingText}>
            <h1>Students' Union</h1>
            <h3>BITS Pilani, Pilani Campus</h3>
          </div>
        </div>
        <div className={styles.panelContents}>
          <div className={styles.footer}>
            <div
              style={{
                width: "3.25vw",
                height: "3.25vw",
                background: "tomato",
                borderRadius: "100%",
              }}
            >
              <img
                src={User}
                alt=''
                style={{
                  width: "3.25vw",
                  height: "3.25vw",
                  borderRadius: "100%",
                }}
              />
            </div>
            <div className={styles.footerText}>
              <h3>{displayName}</h3>
              <h4>{BITSID}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftUserPanel;
