import styles from "./SigningStats.module.css";
import { useState, useEffect } from "react";
import { signingStats, eventList, merchList } from "../../../api/api";

import {
  AreaChart,
  XAxis,
  Tooltip,
  CartesianGrid,
  YAxis,
  ResponsiveContainer,
  Area,
} from "recharts";

import { AntSwitch } from "../../../Components/AntSwitch";
import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const SigningStats = () => {
  const [stats, setStats] = useState([]);
  const [data, setData] = useState([]);
  const [checked, setChecked] = useState(true);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    checked
      ? merchList()
          .then((result) => {
            setData(result.data);
            setLoader(false);
          })
          .catch((err) => console.log(err))
      : eventList()
          .then((result) => {
            setData(result.data);
            setLoader(false);
          })
          .catch((err) => console.log(err));
  }, [checked]);

  useEffect(() => {
    signingStats() //api
      .then((result) => {
        setStats(result.data);
      })
      .catch((err) => console.log(err));
  }, []);

  console.log(stats);

  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };

  return (
      <div>
      {{ loader }.loader ? (
        <div className={styles.loader}>
          <center>
            <CircularProgress></CircularProgress>
          </center>
        </div>
      ) : (
      <div className={styles.homeContainer}>
      <div className={styles.title}>
        <h1>Student's Union Technical Team</h1>
        <h2>Home / Signing Statistics</h2>
      </div>
      <div className={styles.contentBox}>
        <div>
        {checked ? (
          <h1>Merchandise Statistics</h1>
          ): (
            <h1>Event Statistics</h1>
          )}
        </div>
        <FormGroup>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography>Events</Typography>
            <AntSwitch
              inputProps={{ "aria-label": "ant design" }}
              checked={checked}
              onChange={handleToggle}
            />
            <Typography>Merch</Typography>
          </Stack>
        </FormGroup>
        <ResponsiveContainer width="100%" height="60%">
          <AreaChart
            width={500}
            height={400}
            data={stats}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="hour" tick={false} />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="monotone"
              dataKey="signings"
              stroke="#6FED85"
              fill="#6FED85"
              opacity={0.3}
            />
          </AreaChart>
        </ResponsiveContainer>
        <div className={styles.cards}>
          
        </div>
      </div>
      </div>
      )}
      </div>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.customTooltip}>
        <p className={styles.desc}>
          {label.slice(0, 10)} {label.slice(11, 16)}
        </p>
        <p>Signings: {payload[0].value}</p>
      </div>
    );
  }

  return null;
};

export default SigningStats;
