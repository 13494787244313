import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

export const AntSwitch = styled(Switch)(({ theme }) => ({
    width: "3vw",
    height: "1.5vw",
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(25px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(25px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: "1.25vw",
      height: "1.25vw",
      borderRadius: 10,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 25 / 2,
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#9668d6" : "#794db7",
      boxSizing: "border-box",
    },
  }));
